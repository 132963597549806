<template>
  <div :class="'level level-'+this.d">
    <q-card class="row q-mb-xs q-pa-md">
      <div class="col">
        <router-link :to="{ name: 'categories_view', params: {id: node.id }}">{{ node.name }}</router-link>
        <div class="terms-count">
          <template v-if="node.fullTermsCount > 0">
            <template v-if="node.termsCount > 0">
              {{ node.termsCount }} {{ pluralize(node.termsCount, 'слово', 'слова', 'слов') }}
            </template>
            <template v-if="(node.termsCount > 0) && (node.fullTermsCount > node.termsCount)">
              +
            </template>
            <template v-if="node.fullTermsCount > node.termsCount">
              {{ node.fullTermsCount - node.termsCount }} {{ pluralize(node.fullTermsCount - node.termsCount, 'слово', 'слова', 'слов') }} в подрубриках
            </template>
          </template>
          <template v-else>
            Нет слов.
          </template>
        </div>
      </div>
      <div class="col text-right">
        <category-context-menu :category="node" :hook="reload" />
      </div>
    </q-card>

    <template v-if="node.children && node.children.length">
      <cnode v-for="child in node.children" :key="child.id" :node="child" :d="d + 1" @change="change"></cnode>
    </template>
  </div>
</template>

<script>
import CategoryContextMenu from '../../blocks/categories/CategoryContextMenu.vue'

export default {
  name: 'cnode',
  components: {
    CategoryContextMenu,
  },
  emits: ['change'],
  props: {
    node: Object,
    d: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    async reload() {
      this.$emit('change')
    },
    change() {
      this.$emit('change')
    }
  }
};
</script>

<style lang="scss">
.terms-count {
  color: rgba(0, 0, 0, 0.3);
}
</style>
