<template>
  <page-header title="Отрасли">
    <q-btn color="primary" @click="showAddCategoryDialog">
      <img src="../../assets/icons/button/add.svg" class="xs" title="Добавить отрасль" alt="">
      <span class="gt-xs">Добавить отрасль</span>
    </q-btn>
  </page-header>

  <div v-if="categories != null">
    <p v-if="categories.length === 0">Пока нет отраслей.</p>
    <div v-else>
      <cnode v-for="category in categories" :key="category.id" :node="category" @change="reload"/>
    </div>
  </div>
</template>

<script>
import loading from '../../loading.js'
import { api as categoriesApi } from '../../api/categories.js'
import PageHeader from '../../blocks/PageHeader.vue'
import EditCategoryDialog from '../../dialogs/categories/EditCategoryDialog.vue'
import NodeTree from './TreeNode.vue'

export default {
  components: {
    PageHeader,
    cnode: NodeTree,
  },
  async beforeRouteEnter(to, from, next) {
    loading.start()
    const categories = await categoriesApi.findTree()
    next(vm => { vm.categories = categories })
    loading.stop()
    loading.updateTitle(to.meta.title || '')
  },
  data() {
    return {
      categories: null,
    }
  },
  methods: {
    async reload() {
      this.categories = await categoriesApi.findTree()
    },
    showCategoryFormDialog(category) {
      return new Promise(resolve => {
        this.$root
          .createDialog({
            component: EditCategoryDialog,
            componentProps: {
              category: category,
              hook: this.reload,
            },
          })
          .onOk(resolve)
      })
    },
    showAddCategoryDialog() {
      const category = {}
      this
        .showCategoryFormDialog(category)
        .then(category => {
          this.$root.showSuccessMessage(`Отрасль «${category.name}» добавлена.`)
        })
    },
    showEditCategoryDialog(category) {
      this
        .showCategoryFormDialog(category)
        .then(category => {
          this.$root.showSuccessMessage(`Отрасль «${category.name}» обновлёна.`)
        })
    },
  },
}
</script>

<style>
.level {
  margin-left: 50px;
}
.level.level-0 {
  margin-left: 0;
}
</style>
